import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const Login = lazy(() => import("./Pages/auth/login/Login"));
const Register = lazy(() => import("./Pages/auth/register/Register"));
const ForgotPassword = lazy(() =>
  import("./Pages/auth//forgot-password/ForgotPassword")
);
const InterviewBuddy = lazy(() =>
  import("./Pages/interview-buddy/InterviewBuddy")
);
const GuestMigration = lazy(() => import("./Pages/auth/guest-migration/Login"));
const Bookmark = lazy(() =>
  import("./Pages/interview-buddy/bookmark/Bookmark")
);
const PageNotFound = lazy(() => import("./Pages/page-not-found"));
const CoverLetter = lazy(() => import("./Pages/cover-letter"));

// Individual Resume
const ResumeBasicInfo = lazy(() => import("./Pages/resume/basicinfo-form"));
const ResumeDraft = lazy(() => import("./Pages/resume/resume-draft"));
const ResumeExperience = lazy(() => import("./Pages/resume/experience-form"));
const SelectResumeTemplate = lazy(() =>
  import("./Pages/resume/select-template")
);
const SelectResumeType = lazy(() =>
  import("./Pages/resume/select-resume-type")
);
const ExistingResumeUpload = lazy(() =>
  import("./Pages/resume/resume-type-create-option/existing-resume-upload")
);
const LinkedInResumeUpload = lazy(() =>
  import("./Pages/resume/resume-type-create-option/LinkedIn-resume-upload")
);
const CreateResumeSummary = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/summary")
);
const CreateResumeExperience = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/experience")
);
const CreateResumeCoreSkills = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/core-skills")
);
const CreateResumeTechnicalSkills = lazy(() =>
  import(
    "./Pages/resume/resume-type-create-option/create-resume/technical-skill"
  )
);
const CreateResumeProjects = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/projects")
);
const CreateResumeCertification = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/certification")
);
const CreateResumeEducation = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/education")
);
const CreateResumeResume = lazy(() =>
  import("./Pages/resume/resume-type-create-option/create-resume/resume")
);
const CreateResumeChooseResumeCategory = lazy(() =>
  import(
    "./Pages/resume/resume-type-create-option/create-resume/choose-resume-category"
  )
);
const ResumeAtsFeedback = lazy(() => import("./Pages/resume/ATS-feedback"));
const AtsFeedbackUploadResume = lazy(() =>
  import("./Pages/resume/ATS-feedback/ATS-feedback-upload-resume")
);
const ShowATsFeedbackForResume = lazy(() =>
  import(
    "./Pages/resume/ATS-feedback/ATS-feedback-upload-resume/Show-ATS-feedback"
  )
);
const LinkedInHelpResume = lazy(() => import("./Pages/resume/linkedin-help"));

// Individual Resume close

// Resume-writer
const ResumeWriter = lazy(() => import("./Pages/resume-writer/dashboard"));
const ResumeWriterMyClients = lazy(() =>
  import("./Pages/resume-writer/my-clients")
);
const ResumeWriterBasicInfoForm = lazy(() =>
  import("./Pages/resume-writer/my-clients/basicinfo-form")
);
const ResumeWriterExperienceInfoForm = lazy(() =>
  import("./Pages/resume-writer/my-clients/experience-form")
);
const ResumeWriterResumeDraft = lazy(() =>
  import("./Pages/resume-writer/my-clients/resume-draft")
);
const SelectResumeTypeForWriter = lazy(() =>
  import("./Pages/resume-writer/my-clients/select-resume-type")
);
const SelectResumeWriterTemplate = lazy(() =>
  import("./Pages/resume-writer/my-clients/select-template")
);
const OnboardResumeWriter = lazy(() => import("./Pages/resume-writer/onboard"));
const LinkedInContact = lazy(() =>
  import("./Pages/resume-writer/onboard/contact")
);
const ExistingResumeUploadForWriter = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/existing-resume-upload"
  )
);
const LinkedInResumeUploadForWriter = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/linkedIn-resume-upload"
  )
);
const ChooseResumeCategoryWriter = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/choose-resume-category"
  )
);
const CreateWriterResumeSummary = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/summary"
  )
);
const CreateWriterResumeExperience = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/experience"
  )
);
const CreateWriterResumeCoreSkills = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/core-skills"
  )
);
const CreateWriterResumeTechnicalSkills = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/technical-skills"
  )
);
const CreateWriterResumeTechnicalProjects = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/Projects"
  )
);
const CreateWriterResumeCertifications = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/certifications"
  )
);
const CreateWriterResumeEducation = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/education"
  )
);
const CreateWriterResumeResumeDraft = lazy(() =>
  import(
    "./Pages/resume-writer/my-clients/resume-type-create-option/create-resume/resume"
  )
);
const AllClients = lazy(() => import("./Pages/resume-writer/all-clients"));
const LinkedInUsers = lazy(() => import("./Pages/resume-writer/linkedin-users"));

const WriterManageWl = lazy(() => import("./Pages/resume-writer/manage-wl"));
const AllUserInfo = lazy(() => import("./Pages/resume-writer/all-users"));
const WriterAtsFeedback = lazy(() =>
  import("./Pages/resume-writer/ATS-feedback")
);
// Resume-writer close

//Edit-client-resume-by-admin
const EditClientResume = lazy(() =>
  import("./Pages/resume-writer/all-clients/edit-client-resume-by-admin/resume")
);
const EditClientResumeBasicInfo = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/basinInfo"
  )
);
const EditClientResumeCategory = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/select-experience-category"
  )
);
const EditClientResumeSummary = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/summary"
  )
);
const EditClientResumeExperience = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/experience"
  )
);
const EditClientResumeCoreSkill = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/core-skills"
  )
);
const EditClientResumeTechnicalSkill = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/technical-skill"
  )
);
const EditClientResumeProjects = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/projects"
  )
);
const EditClientResumeCertifications = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/certifications"
  )
);
const EditClientResumeEducation = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/education"
  )
);
const EditClientResumeAtsFeedback = lazy(() =>
  import(
    "./Pages/resume-writer/all-clients/edit-client-resume-by-admin/ats-feedback"
  )
);
//Edit-client-resume-by-admin close

// submit-information
const SubmitInfoHome = lazy(() => import("./Pages/submit-information/home"));
const SubmitInfoSelectExperienceLevel = lazy(() =>
  import("./Pages/submit-information/select-experience-level")
);
const SubmitInfoBasicInfo = lazy(() =>
  import("./Pages/submit-information/basic-info")
);
const SubmitInfoSummary = lazy(() =>
  import("./Pages/submit-information/summary")
);
const SubmitInfoExperience = lazy(() =>
  import("./Pages/submit-information/experience")
);
const SubmitInfoCoreSkills = lazy(() =>
  import("./Pages/submit-information/core-skills")
);
const SubmitInfoTechnicalSkills = lazy(() =>
  import("./Pages/submit-information/technical-skills")
);
const SubmitInfoProjects = lazy(() =>
  import("./Pages/submit-information/projects")
);
const SubmitInfoCertifications = lazy(() =>
  import("./Pages/submit-information/certifications")
);
const SubmitInfoEducations = lazy(() =>
  import("./Pages/submit-information/educations")
);
const SubmitInfoThankYou = lazy(() =>
  import("./Pages/submit-information/thank-you")
);
// submit-information close

// other
const AskForProfessionalConsultation = lazy(() =>
  import("./Pages/ask-for-professional-consultaion")
);
const Contact = lazy(() =>
  import("./Pages/ask-for-professional-consultaion/contact")
);
const Home = lazy(() => import("./Pages/home"));
const HomeSelectTemplate = lazy(() => import("./Pages/home/select-template"));
const Subscription = lazy(() => import("./Pages/subscription"));
const Courses = lazy(() => import("./Pages/course"));
const CarrerChoosing = lazy(() => import("./Pages/carrer-choosing"));
const CarrerChoosingResult = lazy(() =>
  import("./Pages/carrer-choosing/result")
);
const ResumeServices = lazy(() => import("./Pages/resume-services"));
const Testimonials = lazy(() => import("./Pages/testimonials"));

import Sidebar from "./Components/common/Sidebar";
import MainHeader from "./Components/common/MainHeader";
import Loader from "./Components/common/Loader";
import SubmitInfoHeader from "./Components/submit-information/Header";
import PrivateRoute from "./Components/common/PrivateRoute";
import SubmitInfoPrivateRoute from "./Components/submit-information/PrivateRoute";
import { AuthProvider } from "./Contexts/AuthContext";

const Routers = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState("");
  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    const urlArray = pathname.split("/");
    const page = urlArray[urlArray.length - 2];

    setPage(page);
    setPagesList(urlArray);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        {["create-resume", "resume"].includes(page) ? (
          <></>
        ) : page === "create-writer-resume" ? (
          <></>
        ) : page === "edit-client-resume-by-admin" ? (
          <></>
        ) : pagesList.includes("submit-information") ? (
          <SubmitInfoHeader />
        ) : (
          <MainHeader />
        )}

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/select-template"
            element={<HomeSelectTemplate />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/guest-migration"
            element={
              <PrivateRoute>
                <GuestMigration />
              </PrivateRoute>
            }
          />
          <Route
            path="/interview"
            element={
              <PrivateRoute>
                <InterviewBuddy />
              </PrivateRoute>
            }
          />
          {/*<Route
            path="/emailWriter/*"
            element={
              <PrivateRoute>
                <EmailWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/contentWriter/*"
            element={
              <PrivateRoute>
                <ContentWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/giftFinder/*"
            element={
              <PrivateRoute>
                <GiftFinder />
              </PrivateRoute>
            }
          />*/}
          <Route
            path="/interview/bookmark"
            element={
              <PrivateRoute>
                <Bookmark />
              </PrivateRoute>
            }
          />

          {/*Individual Resume */}
          <Route
            path="/basic-info"
            element={
              <PrivateRoute>
                <ResumeBasicInfo />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/draft"
            element={
              <PrivateRoute>
                <ResumeDraft />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/experience"
            element={
              <PrivateRoute>
                <ResumeExperience />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume/select-template"
            element={
              <PrivateRoute>
                <SelectResumeTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/summary"
            element={
              <PrivateRoute>
                <CreateResumeSummary />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/experience"
            element={
              <PrivateRoute>
                <CreateResumeExperience />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/core-skills"
            element={
              <PrivateRoute>
                <CreateResumeCoreSkills />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/technical-skills"
            element={
              <PrivateRoute>
                <CreateResumeTechnicalSkills />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/projects"
            element={
              <PrivateRoute>
                <CreateResumeProjects />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/certifications"
            element={
              <PrivateRoute>
                <CreateResumeCertification />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/education"
            element={
              <PrivateRoute>
                <CreateResumeEducation />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-resume/resume"
            element={
              <PrivateRoute>
                <CreateResumeResume />
              </PrivateRoute>
            }
          />
          <Route
            path="/choose-resume-category"
            element={
              <PrivateRoute>
                <CreateResumeChooseResumeCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/select-resume-type"
            element={
              <PrivateRoute>
                <SelectResumeType />
              </PrivateRoute>
            }
          />
          <Route
            path="/existing-resume-upload"
            element={
              <PrivateRoute>
                <ExistingResumeUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-upload-by-linkedIn"
            element={
              <PrivateRoute>
                <LinkedInResumeUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-ATS-feedback"
            element={
              <PrivateRoute>
                <ResumeAtsFeedback />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-resume-for-ATS-feedback"
            element={
              <PrivateRoute>
                <AtsFeedbackUploadResume />
              </PrivateRoute>
            }
          />
          <Route
            path="/ATS-feedback-for-uploaded-resume"
            element={
              <PrivateRoute>
                <ShowATsFeedbackForResume />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-linkedIn-help"
            element={
              <PrivateRoute>
                <LinkedInHelpResume />
              </PrivateRoute>
            }
          />
          {/*Individual Resume close */}

          {/* Resume-writer */}
          <Route
            path="/resume-writer"
            element={
              <PrivateRoute>
                <Sidebar>
                  <ResumeWriter />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/my-clients"
            element={
              <PrivateRoute>
                <Sidebar>
                  <ResumeWriterMyClients />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/my-clients/basic-info"
            element={
              <PrivateRoute>
                <ResumeWriterBasicInfoForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/my-clients/experience"
            element={
              <PrivateRoute>
                <ResumeWriterExperienceInfoForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/my-clients/resume-draft"
            element={
              <PrivateRoute>
                <ResumeWriterResumeDraft />
              </PrivateRoute>
            }
          />

          <Route
            path="/resume-writer/my-clients/select-template"
            element={
              <PrivateRoute>
                <SelectResumeWriterTemplate />
              </PrivateRoute>
            }
          />

          <Route
            path="/resume-writer/my-clients/select-resume-type"
            element={
              <PrivateRoute>
                <SelectResumeTypeForWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/my-clients/existing-resume-upload"
            element={
              <PrivateRoute>
                <ExistingResumeUploadForWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/my-clients/resume-upload-by-linkedIn"
            element={
              <PrivateRoute>
                <LinkedInResumeUploadForWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/choose-resume-category-by-writer"
            element={
              <PrivateRoute>
                <ChooseResumeCategoryWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/summary"
            element={
              <PrivateRoute>
                <CreateWriterResumeSummary />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/experience"
            element={
              <PrivateRoute>
                <CreateWriterResumeExperience />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/core-skills"
            element={
              <PrivateRoute>
                <CreateWriterResumeCoreSkills />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/technical-skills"
            element={
              <PrivateRoute>
                <CreateWriterResumeTechnicalSkills />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/projects"
            element={
              <PrivateRoute>
                <CreateWriterResumeTechnicalProjects />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/certifications"
            element={
              <PrivateRoute>
                <CreateWriterResumeCertifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/education"
            element={
              <PrivateRoute>
                <CreateWriterResumeEducation />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-writer-resume/resume"
            element={
              <PrivateRoute>
                <CreateWriterResumeResumeDraft />
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/all-clients"
            element={
              <PrivateRoute>
                <Sidebar>
                  <AllClients />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/linkedin-users"
            element={
              <PrivateRoute>
                <Sidebar>
                  <LinkedInUsers />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/white-label"
            element={
              <PrivateRoute>
                <Sidebar>
                  <WriterManageWl />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/users"
            element={
              <PrivateRoute>
                <Sidebar>
                  <AllUserInfo />
                </Sidebar>
              </PrivateRoute>
            }
          />
          <Route
            path="/resume-writer/ATS-feedback"
            element={
              <PrivateRoute>
                <WriterAtsFeedback />
              </PrivateRoute>
            }
          />
          {/* Resume-writer close */}

          {/* Edit-client-resume-by-admin */}
          <Route
            path="/edit-client-resume-by-admin/resume"
            element={
              <PrivateRoute>
                <EditClientResume />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/basic-info"
            element={
              <PrivateRoute>
                <EditClientResumeBasicInfo />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/select-experience-category"
            element={
              <PrivateRoute>
                <EditClientResumeCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/summary"
            element={
              <PrivateRoute>
                <EditClientResumeSummary />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/experience"
            element={
              <PrivateRoute>
                <EditClientResumeExperience />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/core-skills"
            element={
              <PrivateRoute>
                <EditClientResumeCoreSkill />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/technical-skills"
            element={
              <PrivateRoute>
                <EditClientResumeTechnicalSkill />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/projects"
            element={
              <PrivateRoute>
                <EditClientResumeProjects />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/certifications"
            element={
              <PrivateRoute>
                <EditClientResumeCertifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/education"
            element={
              <PrivateRoute>
                <EditClientResumeEducation />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-client-resume-by-admin/ATS-feedback"
            element={
              <PrivateRoute>
                <EditClientResumeAtsFeedback />
              </PrivateRoute>
            }
          />

          {/* Edit-client-resume-by-admin close */}

          {/* submit-information */}
          <Route path="/submit-information" element={<SubmitInfoHome />} />
          <Route
            path="/submit-information/select-experience-level"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoSelectExperienceLevel />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/basic-info"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoBasicInfo />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/summary"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoSummary />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/experience"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoExperience />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/core-skills"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoCoreSkills />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/technical-skills"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoTechnicalSkills />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/projects"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoProjects />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/certifications"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoCertifications />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/education"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoEducations />
              </SubmitInfoPrivateRoute>
            }
          />
          <Route
            path="/submit-information/thank-you"
            element={
              <SubmitInfoPrivateRoute>
                <SubmitInfoThankYou />
              </SubmitInfoPrivateRoute>
            }
          />
          {/* submit-information close */}

          {/* Others */}
          <Route
            path="/ask-for-professional-consultation"
            element={
              <PrivateRoute>
                <AskForProfessionalConsultation />
              </PrivateRoute>
            }
          />
          <Route
            path="/ask-for-professional-consultation/contact"
            element={
              <PrivateRoute>
                <Contact />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboard-resume-writer"
            element={
              <PrivateRoute>
                <OnboardResumeWriter />
              </PrivateRoute>
            }
          />
          <Route
            path="/onboard-resume-writer/contact"
            element={<LinkedInContact />}
          />

          <Route
            path="/cover-letter"
            element={
              <PrivateRoute>
                <CoverLetter />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          />
          <Route path="/courses" element={<Courses />} />
          <Route
            path="/carrer-choosing"
            element={
              <PrivateRoute>
                <CarrerChoosing />
              </PrivateRoute>
            }
          />
          <Route
            path="/carrer-choosing/result"
            element={
              <PrivateRoute>
                <CarrerChoosingResult />
              </PrivateRoute>
            }
          />
          <Route path="/services" element={<ResumeServices />} />
          <Route path="/testimonials" element={<Testimonials />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthProvider>
    </Suspense>
  );
};

export default Routers;
